import firebase from 'firebase';

// Your web app's Firebase configuration
let fireBaseConfig = {

    apiKey: "AIzaSyDoehcSUDndN3kbunOWwP5qUC199k6Th4w",
    authDomain: "bayonapp-2021.firebaseapp.com",
    projectId: "bayonapp-2021",
    storageBucket: "bayonapp-2021.appspot.com",
    messagingSenderId: "420062257952",
    appId: "1:420062257952:web:835b0c947b458a562aad45",
    measurementId: "G-QR61WWSQ4T"

    // apiKey: "AIzaSyDyaZgvmUav042iYfPKz3opDB4TDM6sxd8",
    // authDomain: "uat-bayonapp.firebaseapp.com",
    // projectId: "uat-bayonapp",
    // storageBucket: "uat-bayonapp.appspot.com",
    // messagingSenderId: "499750719467",
    // appId: "1:499750719467:web:18f97eba1fe996c54193ae",
    // measurementId: "G-8BGD29VMSV"

    // Buyhousingonlines@gmail.com
    /*apiKey: "AIzaSyA04H3pAdEpFI-wRnGRUCShau1CwBZzLj8",
    authDomain: "buyhousing-1496d.firebaseapp.com",
    databaseURL: "https://buyhousing-1496d.firebaseio.com",
    projectId: "buyhousing-1496d",
    storageBucket: "buyhousing-1496d.appspot.com",
    messagingSenderId: "753125924133",
    appId: "1:753125924133:web:b752b042db2adf49a20ecc",
    measurementId: "G-9QEHWSWH60"*/

    /* //komshan98@gmail.com
     apiKey: "AIzaSyAlxp0-t3bNJKncqHEvUzxqJQUC77_B5UM",
     authDomain: "imgsaleland.firebaseapp.com",
     databaseURL: "https://imgsaleland.firebaseio.com",
     projectId: "imgsaleland",
     storageBucket: "imgsaleland.appspot.com",
     messagingSenderId: "268781269556",
     appId: "1:268781269556:web:5896657d8757c92e9a1061",
     measurementId: "G-MEE3VEK2B8"*/

    /*//New narongsao98@gmail.com
    apiKey: "AIzaSyALIuxLTf4bpY2kdoYSdsRKTp9bHtHMuGA",
    authDomain: "allphoto.firebaseapp.com",
    databaseURL: "https://allphoto.firebaseio.com",
    projectId: "allphoto",
    storageBucket: "allphoto.appspot.com",
    messagingSenderId: "182136624785",
    appId: "1:182136624785:web:b9f7c7e5e1df6112511130",
    measurementId: "G-VSH9KK4CT1"*/

    //Old Sao Narong
    /*apiKey: "AIzaSyBNQGbtTC4O8uWIVSqr6Q9AvRC1ILTCnWs",
    authDomain: "imgqwer-d3737.firebaseapp.com",
    databaseURL: "https://imgqwer-d3737.firebaseio.com",
    projectId: "imgqwer-d3737",
    storageBucket: "imgqwer-d3737.appspot.com",
    messagingSenderId: "483438187070",
    appId: "1:483438187070:web:2546770b6545d9f2ed776f"*/
};

// Initialize Firebase
firebase.initializeApp(fireBaseConfig);
